import { Injectable } from '@angular/core';
import { Gateway } from '../classes/gateway';
import { APIService } from './api.service';
import { StoreService } from './store.service';

@Injectable({
  providedIn: 'root'
})
export class GatewayService {


  static readonly STATUS_TYPES = ['', 'Configuration', 'Testing', 'Deployed', 'Deleted', 'Stock', 'Discovered', 'New', 'Delivered', 'Faulty', 'Demo', 'Engineer', 'Cancelled'];

  constructor(private apiService: APIService, private storeService: StoreService) { }

  getFavouriteGateways(): string[] {
    const org = this.apiService.getUserOrg();
    const key = `org:${org.id}:gateways:fav`;
    const existing: string[] = (localStorage.getItem(key) || "").split(',').filter(i => i);

    return existing;
  }

  saveFavouriteGateways(gatewayList: string[]) {
    const org = this.apiService.getUserOrg();
    const key = `org:${org.id}:gateways:fav`;
    localStorage.setItem(key, gatewayList.join(','));
  }

  toggleFavouritGateway(gateway: Gateway): string[] {
    const existing = this.getFavouriteGateways();
    let newList: string[];
    if (existing.findIndex(i => i === gateway.id) !== -1) {
      newList = existing.filter(i => i !== gateway.id);
    } else {
      newList = [...existing, gateway.id];
    }
    this.saveFavouriteGateways(newList);

    return newList;
  }

}
