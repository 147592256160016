@defer (when rows() && dataTable()) {
  <header>
    @if (canFilterByAssetType()) {
      <app-data-table-filter-types [sensorTypes]="sensorTypes()" (onSensorTypeSelected)="sensorTypeChange($event)"></app-data-table-filter-types>
    }
  </header>
  <table class="table" [class.table-hover]="canClick()">
    <thead>
      <tr>
        @for (column of dataTable().columns; track column.attribute) {
          <!-- Onlt show header if not hidden or has a title -->
          @if (!column.isHidden) {
            @if (column.title !== '') {
              <th [width]="column.width ? column.width + 'px' : ''">
                <div (click)="sortColumnClick(column)" [pTooltip]="column.tooltip">
                  <div>
                    @if (column.title !== 'RAG') {
                      {{ column.title }}
                    } @else {
                      <!-- RAG title supressed-->
                    }
                  </div>
                  <div>
                    <i tooltipPosition="top" [pTooltip]="column.sortDirection === 'asc' ? 'Ascending, sort by descending' : column.sortDirection === 'desc' ? 'Descending, sort by ascending' : 'Not sorted, sort ascending'" class="mdi" [class.mdi-sort-ascending]="column.sortDirection === 'asc'" [class.mdi-sort-descending]="column.sortDirection === 'desc'" [class.mdi-sort-reverse-variant]="column.sortDirection !== 'asc' && column.sortDirection !== 'desc'"></i>
                  </div>
                </div>
              </th>
            } @else {
              <!-- if no title, still set width but no sorting -->
              <th [width]="column.width ? column.width + 'px' : ''" class="no-click">
                <div></div>
              </th>
            }
          }
        }
      </tr>
    </thead>
    <tbody>
      @for (row of rows(); track row) {
        <tr (click)="rowClick(row)" [class.is-disabled]="row?.isEnabled === false || row?.isActive === false" [class.is-in-alert]="row?.isInAlert === true">
          @for (column of dataTable().columns; track column) {
            @if (!column.isHidden) {
              @switch (column.dataType) {
                @case ('rag') {
                  <td class="rag-{{ row[column.attribute] }}"></td>
                }
                @case ('boolean') {
                  <td class="text-{{ dataTable().dataClassConfig[column.attribute].align }}">{{ row[column.attribute] ? 'Yes' : 'No' }}</td>
                }
                @case ('image') {
                  <td>
                    @if (row[column.attribute]) {
                      @switch (column.dataFormat) {
                        @case ('logo') {
                          <img class="logo" src="https://s3.eu-west-2.amazonaws.com/4d-documents/{{ row[column.attribute] }}" alt="logo" />
                        }
                      }
                    }
                  </td>
                }
                @case ('date') {
                  <!-- prettier-ignore -->
                  @switch(column.dataFormat) {
                   @case ('time') {
                       <td class="text-{{ dataTable().dataClassConfig[column.attribute].align }}">{{ row[column.attribute] | date:'HH:mm' }}</td>
                    }
                    @case ('date') {
                       <td class="text-{{ dataTable().dataClassConfig[column.attribute].align }}">{{ row[column.attribute] | date:'dd/MM/YY' }}</td>
                    }
                    @default {
                      <td class="text-{{ dataTable().dataClassConfig[column.attribute].align }}">{{ row[column.attribute] | date:'dd/MM/YY HH:mm' }}</td>
                    }
                   }
                }
                @default {
                  @switch (column.dataFormat) {
                    @case ('secret') {
                      <td class="text-{{ dataTable().dataClassConfig[column.attribute].align }} secret">
                        <div><span> reveal </span></div>
                        <span>{{ row[column.attribute] }}</span>
                      </td>
                    }
                    @default {
                      <td class="text-{{ dataTable().dataClassConfig[column.attribute].align }}">{{ row[column.attribute] }}</td>
                    }
                  }
                }
              }
            }
          }
        </tr>
      }
    </tbody>
  </table>
} @placeholder {
  <i class="mdi mdi-loading mdi-spin-x2"></i>
}
