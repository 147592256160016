import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'amTimeAgo',
  standalone: false
})
export class AmTimeAgoPipe implements PipeTransform {

  transform(value: any, removeSuffix: boolean = false): string {
    let string = moment(value).fromNow(removeSuffix);
    if (string === 'a few seconds ago') {
      // make sure....
      const minutes = moment(value).diff(moment(), 'minutes');
      string = moment(value).diff(moment(), 'seconds') < 30 ? 'just now' : (minutes < 1) ? 'less than a minute ago' : `${minutes} minutes ago`;
    };

    return string;
  }
}
