import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { PageTitleComponent } from '../../layout/page-title/page-title.component';
import { SchematicsCardComponent } from '../../layout/schematic/schematics-card/schematics-card.component';
import { TreeModule } from 'primeng/tree';
import { CalendarModule } from 'primeng/calendar';
import { PasswordModule } from 'primeng/password';
import { SchematicsStudioComponent } from '../../layout/schematic/schematics-studio/schematics-studio.component';
import { SchematicsDetailContainerComponent } from '../../layout/schematic/schematics-detail-container/schematics-detail-container.component';
import { InfoPanelComponent } from "../../layout/info-panel/info-panel.component";
import { PowerHourComponent } from '../../layout/power/power-hour/power-hour.component';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from "primeng/checkbox";
import { ToolbarModule } from "primeng/toolbar";
import { TooltipModule } from 'primeng/tooltip';
import { PleaseWaitComponent } from "../../layout/please-wait/please-wait.component";
import { ButtonComponent } from "../../layout/button/button.component";
import { SelectComponent } from "../../layout/select/select.component";
import { ToolbarComponent } from "../../layout/toolbar/toolbar.component";
import { FormsModule } from '@angular/forms';
import { DialogComponent } from '../../layout/dialog/dialog.component';
import { PanelComponent } from '../../layout/panel/panel.component';
import { SearchInputComponent } from 'app/chips/search-input/search-input.component';
import { AmCalendarPipe } from 'app/pipes/am-calendar.pipe';
import { AmDateFormatPipe } from 'app/pipes/am-date-format.pipe';
import { AmTimeAgoPipe } from 'app/pipes/am-time-ago.pipe';
import { WeatherTempComponent } from 'app/chips/weather-temp/weather-temp.component';
import { ChipComponent } from 'app/layout/chip/chip.component';
import { ButtonNotificationsComponent } from "app/layout/button-notifications/button-notifications.component";
import { ButtonAssetsComponent } from 'app/layout/button-assets/button-assets.component';
import { DialogSelectComponent } from 'app/dialogs/dialog-select/dialog-select.component';
import { ExplorerComponent } from 'app/developer/explorer/explorer.component';
import { ExplorerAssetComponent } from 'app/developer/explorer/explorer-asset/explorer-asset.component';
import { ExplorerSiteComponent } from 'app/developer/explorer/explorer-site/explorer-site.component';
import { ExplorerChartComponent } from 'app/developer/explorer/explorer-chart/explorer-chart.component';
import { SetpointQueryOccComponent } from 'app/setpoints/setpoint-query/setpoint-query-occ/setpoint-query-occ.component';
import { SetpointQueryOccFloorsComponent } from 'app/setpoints/setpoint-query/setpoint-query-occ-floors/setpoint-query-occ-floors.component';
import { SetpointQueryOccPlanComponent } from 'app/setpoints/setpoint-query/setpoint-query-occ-plan/setpoint-query-occ-plan.component';
import { SetpointQueryOccSquaresComponent } from 'app/setpoints/setpoint-query/setpoint-query-occ-squares/setpoint-query-occ-squares.component';
import { SetpointQueryOccConfigComponent } from 'app/setpoints/setpoint-query/setpoint-query-occ-config/setpoint-query-occ-config.component';
import { SetpointQueryOccTenservComponent } from 'app/setpoints/setpoint-query/setpoint-query-occ-tenserv/setpoint-query-occ-tenserv.component';
import { SetpointQueryComponent } from 'app/setpoints/setpoint-query/setpoint-query.component';
import { SetpointQueryHeaderComponent } from 'app/setpoints/setpoint-query/setpoint-query-header/setpoint-query-header.component';
import { SetpointQueryTableComponent } from "app/setpoints/setpoint-query/setpoint-query-table/setpoint-query-table.component";
import { SetpointQueryControlComponent } from 'app/setpoints/setpoint-query/setpoint-query-control/setpoint-query-control.component';

import { SetpointListComponent } from 'app/setpoints/setpoint-list/setpoint-list.component';
import { SetpointsExplorerComponent } from "app/setpoints/setpoints-explorer/setpoints-explorer.component";
import { SetpointDatesButtonComponent } from 'app/setpoints/setpoint-dates-button/setpoint-dates-button.component';
import { CalendarComponent } from 'app/layout/calendar/calendar.component';
import { AssetViewerComponent } from 'app/assets/asset-viewer/asset-viewer.component';
import { SqPowerComponent } from 'app/setpoints/setpoint-query/plugins/sq-power/sq-power.component';
import { D3BarComponent } from 'app/charts/d3-bar/d3-bar.component';
import { D3ChartComponent } from 'app/charts/d3-chart/d3-chart.component';
import { D3OccupancyComponent } from 'app/charts/d3-occupancy/d3-occupancy.component';
import { D3SiteRagChartComponent } from 'app/charts/d3-site-rag-chart/d3-site-rag-chart.component';
import { ThemePageContainerComponent } from 'app/theme/theme-page-container/theme-page-container.component';
import { ThemePageSectionComponent } from 'app/theme/theme-page-section/theme-page-section.component';
import { ButtonDatesComponent } from 'app/layout/button-dates/button-dates.component';
import { ButtonSensorsComponent } from 'app/layout/button-sensors/button-sensors.component';
import { ButtonTriggerComponent } from 'app/layout/button-trigger/button-trigger.component';
import { MapComponent } from 'app/shared/components/map/map.component';
import { DataFormComponent } from 'app/layout/data-form/data-form.component';
import { AssetIconComponent } from 'app/chips/asset-icon/asset-icon.component';
import { AssetMonitorComponent } from 'app/assets/asset-monitor/asset-monitor.component';
import { TooltipDirective } from 'app/layout/directives/tooltip.directive';
import { AssetDashboardComponent } from 'app/charts/asset-dashboard/asset-dashboard.component';
import { ButtonAssetAdminComponent } from 'app/layout/button-asset-admin/button-asset-admin.component';
import { DataTableHeaderComponent } from 'app/layout/data-table/data-table-header/data-table-header.component';
import { DataTableComponent } from 'app/layout/data-table/data-table/data-table.component';
import { DataTableFilterTypesComponent } from 'app/layout/data-table/data-table-filter-types/data-table-filter-types.component';
import { SetpointQueryQuestionsComponent } from 'app/setpoints/setpoint-query/setpoint-query-questions/setpoint-query-questions.component';

import { ConfirmationService, MessageService } from 'primeng/api';
import { BlockUIModule } from "primeng/blockui";
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { CardModule } from 'primeng/card';
import { ChartModule } from 'primeng/chart';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { DragDropModule } from 'primeng/dragdrop';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogModule } from "primeng/dynamicdialog";
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ListboxModule } from 'primeng/listbox';
import { MenuModule } from 'primeng/menu';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelModule } from 'primeng/panel';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SelectButtonModule } from "primeng/selectbutton";
import { SidebarModule } from 'primeng/sidebar';
import { SliderModule } from 'primeng/slider';
import { SplitButtonModule } from 'primeng/splitbutton';
import { StepsModule } from 'primeng/steps';
import { TabMenuModule } from 'primeng/tabmenu';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TimelineModule } from 'primeng/timeline';
import { ToastModule } from 'primeng/toast';
import { ImageModule } from "primeng/image";
import { OverlayModule } from 'primeng/overlay';
import { EnergyAssetGraphComponent } from 'app/energy/components/energy-asset-graph/energy-asset-graph.component';
import { OccPercentageBarComponent } from 'app/layout/occ-percentage-bar/occ-percentage-bar.component';
import { DropdownComponent } from 'app/layout/dropdown/dropdown.component';
import { MonthSelectComponent } from 'app/layout/month-select/month-select.component';
import { RatingAeComponent } from 'app/layout/rating-ae/rating-ae.component';
import { DataNoteworthyTagsComponent } from 'app/layout/data-noteworthy-tags/data-noteworthy-tags.component';

@NgModule({
	declarations: [
		PageTitleComponent,
		SchematicsCardComponent,
		SchematicsStudioComponent,
		SchematicsDetailContainerComponent,
		InfoPanelComponent,
		PowerHourComponent,
		PleaseWaitComponent,
		ButtonComponent,
		SelectComponent,
		ToolbarComponent,
		DialogComponent,
		PanelComponent,
		SearchInputComponent,
		AmDateFormatPipe,
		AmTimeAgoPipe,
		AmCalendarPipe,
		WeatherTempComponent,
		ChipComponent,
		ButtonNotificationsComponent,
		ButtonAssetsComponent,
		DialogSelectComponent,
		ExplorerAssetComponent,
		ExplorerComponent,
		ExplorerSiteComponent,
		ExplorerChartComponent,
		SetpointQueryComponent,
		SetpointQueryHeaderComponent,
		SetpointQueryComponent,
		SetpointQueryOccComponent,
		SetpointQueryOccFloorsComponent,
		SetpointQueryOccPlanComponent,
		SetpointQueryOccSquaresComponent,
		SetpointQueryOccConfigComponent,
		SetpointQueryOccTenservComponent,
		SetpointQueryTableComponent,
		SetpointQueryQuestionsComponent,
		SetpointListComponent,
		SetpointsExplorerComponent,
		SetpointDatesButtonComponent,
		SetpointQueryControlComponent,
		CalendarComponent,
		AssetViewerComponent,
		SqPowerComponent,
		D3BarComponent,
		D3ChartComponent,
		D3OccupancyComponent,
		D3SiteRagChartComponent,
		ThemePageSectionComponent,
		ThemePageContainerComponent,
		ButtonDatesComponent,
		ButtonSensorsComponent,
		ButtonTriggerComponent,
		DataFormComponent,
		MapComponent,
		AssetIconComponent,
		AssetMonitorComponent,
		TooltipDirective,
		AssetDashboardComponent,
		EnergyAssetGraphComponent,
		ButtonAssetAdminComponent,
		OccPercentageBarComponent,
		DropdownComponent,
		MonthSelectComponent,
		RatingAeComponent,
		DataTableHeaderComponent,
		DataTableComponent,
		DataTableFilterTypesComponent,
		DataNoteworthyTagsComponent
	],
	imports: [
		CommonModule,
		TreeModule,
		CalendarModule,
		InputNumberModule,
		InputTextModule,
		CheckboxModule,
		PasswordModule,
		ToolbarModule,
		TooltipModule,
		FormsModule,
		MultiSelectModule,
		OverlayPanelModule,
		ListboxModule,
		PanelModule,
		MessagesModule,
		ChartModule,
		DialogModule,
		ConfirmDialogModule,
		InputSwitchModule,
		SliderModule,
		StepsModule,
		TabMenuModule,
		TableModule,
		TabViewModule,
		DropdownModule,
		ToastModule,
		ScrollPanelModule,
		ToolbarModule,
		SplitButtonModule,
		OverlayModule,
		SidebarModule,
		InputTextareaModule,
		TooltipModule,
		DragDropModule,
		CardModule,
		BreadcrumbModule,
		MenuModule,
		ContextMenuModule,
		BlockUIModule,
		TimelineModule,
		DynamicDialogModule,
		SelectButtonModule,
		ImageModule
	],
	exports: [
		PageTitleComponent,
		SchematicsCardComponent,
		SchematicsStudioComponent,
		InfoPanelComponent,
		PowerHourComponent,
		PleaseWaitComponent,
		CalendarModule,
		TreeModule,
		InputNumberModule,
		InputTextModule,
		CheckboxModule,
		PasswordModule,
		ToolbarModule,
		TooltipModule,
		SplitButtonModule,
		BreadcrumbModule,
		ButtonComponent,
		SelectComponent,
		ToolbarComponent,
		DialogComponent,
		PanelComponent,
		SearchInputComponent,
		AmDateFormatPipe,
		AmTimeAgoPipe,
		AmCalendarPipe,
		WeatherTempComponent,
		ChipComponent,
		ButtonNotificationsComponent,
		ButtonAssetsComponent,
		DialogSelectComponent,
		ExplorerAssetComponent,
		ExplorerComponent,
		ExplorerSiteComponent,
		ExplorerChartComponent,
		SetpointQueryComponent,
		SetpointQueryHeaderComponent,
		SetpointQueryTableComponent,
		SetpointQueryOccComponent,
		SetpointQueryOccFloorsComponent,
		SetpointQueryOccPlanComponent,
		SetpointQueryOccSquaresComponent,
		SetpointQueryOccConfigComponent,
		SetpointQueryOccTenservComponent,
		SetpointListComponent,
		SetpointDatesButtonComponent,
		SetpointQueryControlComponent,
		SetpointQueryQuestionsComponent,
		CalendarComponent,
		AssetViewerComponent,
		SqPowerComponent,
		D3BarComponent,
		D3ChartComponent,
		D3OccupancyComponent,
		D3SiteRagChartComponent,
		ThemePageSectionComponent,
		ThemePageContainerComponent,
		ToastModule,
		ConfirmDialogModule,
		TabViewModule,
		TabMenuModule,
		OverlayModule,
		OverlayPanelModule,
		CardModule,
		ScrollPanelModule,
		DropdownModule,
		MultiSelectModule,
		DialogModule,
		ButtonDatesComponent,
		ButtonSensorsComponent,
		ButtonTriggerComponent,
		DataFormComponent,
		MapComponent,
		AssetIconComponent,
		AssetMonitorComponent,
		TooltipDirective,
		ImageModule,
		TimelineModule,
		SidebarModule,
		SelectButtonModule,
		PanelModule,
		MessagesModule,
		MenuModule,
		ChartModule,
		AssetDashboardComponent,
		EnergyAssetGraphComponent,
		ButtonAssetAdminComponent,
		OccPercentageBarComponent,
		DropdownComponent,
		MonthSelectComponent,
		RatingAeComponent,
		DataTableHeaderComponent,
		DataTableComponent,
		DataTableFilterTypesComponent,
		DataNoteworthyTagsComponent
	],
	providers: [ConfirmationService, MessageService]
})
export class SharedModule { }
