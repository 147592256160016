<article>
  <app-toolbar>
    <div class="left flex">
      @defer (when redis()) {
        @if (redis()?.firstat.getFullYear() > 2020 || redis()?.lastat.getFullYear() > 2020) {
          @if (redis()?.firstat.getFullYear() > 2020) {
            {{ redis()?.firstat | date: 'MMMM YYYY' }} -
          } @else {
            To
          }
          @if (redis()?.lastat.getFullYear() > 2020) {
            {{ redis()?.lastat | date: 'MMMM YYYY' }} available.
          } @else {
            No data
          }
        } @else {
          No data available
        }
      } @placeholder (minimum 200ms) {
        <i class="mdi mdi-loading mdi-spin-x2"></i>
      }
    </div>
    <div class="right">
      <!--<app-button-dates [value]="dates()" (onSelect)="dateChange($event)"></app-button-dates>-->

      <app-button [pTooltip]="wantsPreviousYear() ? 'Click to only show current year' : 'Click to compare to previous year'" [label]="wantsPreviousYear() ? 'Comparing previous year' : 'Only requested year'" icon="mdi mdi-rotate-3d-variant" (click)="toggleComparePrevioousYear()" />
    </div>
  </app-toolbar>
  <main>
    <header>
      <section class="flex">
        <div class="flex-1" pTooltip="Selected year" tooltipPosition="left">
          <div>
            @if (granularity() === 'month') {
              {{ dates().dates.from | date: 'YYYY' }}
            } @else {
              {{ dates().dates.from | date: 'MMMM YYYY' }}
            }
          </div>
          <div>{{ stats().total }}</div>
        </div>
        <div class="flex-1" pTooltip="Previous year" tooltipPosition="left">
          <div>Previous year</div>
          <div>{{ stats().previous }}</div>
        </div>
        <div class="flex-1" [ngClass]="{ worse: stats().diff > 0, better: stats().diff < 0 }">
          <div>Difference</div>
          <div>
            {{ stats().diff }} <span><i class="mdi" [class.mdi-arrow-up-bold]="stats().diff > 0" [class.mdi-arrow-down-bold]="stats().diff < 0"></i> {{ stats().perc }}%</span>
          </div>
        </div>
      </section>
    </header>
    <div class="chart">
      <canvas id="chart-1" (onSelect)="datesSelected($event)"></canvas>
    </div>
  </main>
</article>
