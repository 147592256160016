import { Component, input, OnInit, signal } from '@angular/core';
import { APIService } from 'app/shared/api.service';
import { AssetService, IAssetControls } from 'app/shared/asset.service';

@Component({
  selector: 'app-setpoint-query-control',
  standalone: false,
  templateUrl: './setpoint-query-control.component.html',
  styleUrl: './setpoint-query-control.component.css'
})
export class SetpointQueryControlComponent implements OnInit {

  assetId = input.required<number>();
  controls = signal<IAssetControls>(undefined)
  canControl: boolean;

  constructor(private assetService: AssetService, private apiService: APIService) {

  }

  ngOnInit(): void {
    this.canControl = this.apiService.hasOrgRole('operate_asset') || this.apiService.isAdmin();

    if (this.canControl) {
      this.getSettings();
    } else {
      this.controls.set({ assets: [] });
    }
  }

  getSettings() {
    this.assetService.getControlsForAssets([this.assetId()]).then(list => {
      console.log(list);

      this.controls.set(list);
    });
  }

  clickControl(control) {
    if (!control) {
      return;
    }

    this.apiService.toastSuccess(control.requesting + '..');
    this.assetService.sendActionToAsset(this.assetId(), control.uuid).then(response => {
      this.apiService.toastSuccess('Request completed.');
      console.log(response);
    })
  }
}
