/**
 * key at 4dmanage@
 */
import { environment } from '../../../../environments/environment';
import { Component, Input, signal, effect, OnInit, AfterViewInit, input, OnDestroy } from '@angular/core';
import { Site } from 'app/classes/site';
import { APIService } from 'app/shared/api.service';
import mapboxgl from 'mapbox-gl';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css'],
  standalone: false
})
export class MapComponent implements OnInit, AfterViewInit, OnDestroy {

  mapMarkers = signal<mapboxgl.Marker[]>(null)
  mapId: string;
  map: mapboxgl.Map;
  latlng: mapboxgl.LngLatLike = { lat: 52.9, lng: -1.18 };

  config: any = {};

  @Input()
  public set sites(sites: Site[]) {

  }

  @Input()
  style = 'mapbox://styles/mapbox/streets-v12';

  @Input()
  public set markers(markers: mapboxgl.Marker[]) {
    if (!markers || markers.length === 0) {
      return;
    }
    const didUpdate = (this.mapMarkers()?.length > 0);

    // remove markers already on map
    if (didUpdate) {
      this.mapMarkers().forEach(marker => marker.remove());
    }

    this.mapMarkers.set(markers);
    if (didUpdate) {
      this.applyMarkersIfMap();
    }
  }

  @Input()
  dragPan = true;

  @Input()
  dragRotate = true;

  @Input()
  interactive = true;

  @Input()
  doubleClickZoom = true;

  @Input()
  zoom = 5.8;

  @Input()
  hasControls = true;

  @Input()
  public set isStatic(v: boolean) {
    if (typeof v !== "boolean") {
      return;
    }

    if (v) {
      this.dragPan = false;
      this.dragRotate = false;
      this.interactive = false;
      this.doubleClickZoom = false;
      this.hasControls = false;
    }

  }

  showToolbar: boolean = false;

  _center: mapboxgl.LngLatLike;

  @Input()
  public set center(latlng: mapboxgl.LngLatLike) {
    if (!latlng) {
      return;
    }
    this._center = latlng;
  }

  /**
   * To persist state pass the localstorage key
   */
  mapKey = input<string>(null);
  orgId: number;

  constructor(private apiService: APIService) {
    this.mapId = crypto.randomUUID().replaceAll('-', '');
    console.log(this.mapId);
    effect(() => {
      if (this.mapMarkers()?.length) {
        if (!this.applyMarkersIfMap()) {
          setTimeout(() => {
            if (!this.applyMarkersIfMap()) {
              setTimeout(() => {
                this.applyMarkersIfMap();
              }, 1000);
            };
          }, 500);
        }
      }
    });
  }

  ngAfterViewInit(): void {
    console.log('view init');

    this.orgId = this.apiService.getUserOrg().id;

    (mapboxgl as any).accessToken = environment.mapbox.accessToken;

    this.getLocalstorage();

    this.map = new mapboxgl.Map({
      container: this.mapId,
      style: this.style, // style URL
      zoom: this.config?.map?.zoom ?? this.zoom,
      center: this.config?.map?.center ?? this._center ?? this.latlng,
      dragPan: this.dragPan,
      dragRotate: this.dragRotate,
      doubleClickZoom: this.doubleClickZoom,
      interactive: this.interactive
    });

    if (this.hasControls) {
      this.map.addControl(new mapboxgl.NavigationControl());
    }
  }

  ngOnInit(): void {
    console.log(`oninit`, this.map);
  }

  getLocalstorage() {
    try {
      if (this.mapKey()) {
        const configString = localStorage.getItem(`${this.getLocalstoragePreKey()}:${this.mapKey()}:config`);
        if (!configString) {
          return;
        }

        const map = JSON.parse(configString);

        this.config = map;
      }
    } catch (e) {
      // Ignore errors
    }
  }

  getConfigFromMap() {
    this.config = {
      zoom: this.map.getZoom(),
      center: this.map.getCenter()
    }
  }

  getLocalstoragePreKey() {
    return `org:${this.orgId}`
  }

  saveLocalStorage() {
    this.getConfigFromMap();
    try {
      if (this.mapKey()) {

        const payload = { map: this.config };
        localStorage.setItem(`${this.getLocalstoragePreKey()}:${this.mapKey()}:config`, JSON.stringify(payload));
      }
    } catch (e) {
      // Ignore errors
    }
  }

  applyMarkersIfMap() {
    if (this.map) {
      this.applyMarkers();
      console.log('applied markers');
      return true;
    } else {
      return false;
    }
  }

  applyMarkers() {
    for (const marker of this.mapMarkers()) {
      marker.addTo(this.map);
    }
  }

  clickSitesInAlarm() {
    this.apiService.getRulePackages('alarms-conditions').then(rp => {
      console.log(rp);
      const inAlert = rp.filter(rp => rp.inAlert === 'Y');

    });
  }

  ngOnDestroy(): void {
    this.saveLocalStorage();
  }

}
