import { Component, input, output, signal } from '@angular/core';
import { Asset } from 'app/classes/asset';
import { AssetService } from 'app/shared/asset.service';
import { DateService } from 'app/shared/date.service';

@Component({
  selector: 'app-setpoint-query-questions',
  standalone: false,
  templateUrl: './setpoint-query-questions.component.html',
  styleUrl: './setpoint-query-questions.component.css'
})
export class SetpointQueryQuestionsComponent {

  asset = input.required<Asset>();
  response = signal<string>(null);
  date = signal<Date>(null);

  showOnGraph = output<Date>();

  constructor(private assetService: AssetService) {

  }

  clickShowOnGraph() {
    this.showOnGraph.emit(this.date());
  };

  question(requested: string) {
    switch (requested) {
      case 'last-leak':
        this.questionLastLeak();
    }
  }

  questionLastLeak() {
    this.assetService.askQuestion(this.asset().id, 'last-leaK').then(response => {
      console.log(response);
      if (response.data.length === 0) {
        this.date.set(null);
        this.response.set('No leak has been detected in the last 12 months.');
      } else {
        const value = response.data[0]?.createdAt;

        this.response.set("The last leak was " + DateService.datetime(new Date(value)));
        this.date.set(new Date(value));
      }
    });
  }
}
