/**
 * Asset Service
 */
import { Injectable } from '@angular/core';
import { APIService } from './api.service';
import { Asset, Camera, AssetType, Occupancy, BatteryAsset } from '../classes/asset';
import { Setpoint } from '../classes/setpoint';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Packet } from 'app/classes/packet';
import { StoreService } from './store.service';
import moment from 'moment';
import { OfflineIssue } from './offline-issue';
import { Observable, BehaviorSubject, lastValueFrom } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class AssetService {
  API_URL = 'https://09wuka10gg.execute-api.eu-west-2.amazonaws.com/4d/';

  static readonly ASSET_TYPES_CONTROL = [4, 79, 80];
  static readonly ASSET_TYPE_MOISTURE = 23;
  static readonly ASSET_TYPE_RELAY_NO = 79;
  static readonly ASSET_TYPE_RELAY_NC = 80;

  static readonly ICONS = [
    ,
    'mdi-button',
    'mdi-thermometer-low',
    'mdi-arrow-collapse-horizontal',
    'relay',
    '',
    '',
    'mdi-weather-sunny',
    'mdi-air-humidifier',
    '',
    'switch',
    'switch',
    'mdi-wifi',
    'mdi-file-question 13',
    'mdi-file-question 14',
    'mdi-file-question 15',
    'mdi-file-question 16',
    'mdi-door',
    'mdi-file-question 18',
    'mdi-coolant-temperature',
    '',
    'mdi-battery',
    'mdi-motion?',
    'mdi-moisture?',
    'mdi-distance?',
    'mdi-molecule-co2',
    'mdi-google-circles-communities',
    'mdi-google-circles-communities',
    'mdi-google-circles-communities',
    'mdi-google-circles-communities',
    'mdi-google-circles-communities', // 30 pm 10
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    , 'mdi-coolant-temperature' // 47
    ,
    , 'mdi-electric-switch'
    , 'mdi-electric-switch-closed'
  ];

  public ASSET_TYPE_CAPTURE = 18;
  public categoryIcons = {
    temperature: 'thermometer-quarter',
    power: 'bolt',
    network: 'wifi',
    lux: 'lightbulb-o',
    presure: 'compress',
    humidity: 'tint',
    footfall: 'eye',
    relay: 'puzzle-piece',
    button: 'square-o',
    door: 'sign-in',
    image: 'camera',
    battery: 'battery-full',
    moisture: 'cloud',
    motion: 'users',
    transition: 'exchange',
    distance: 'arrows-v',
    air: 'sun-o',
    wind: 'leaf',
    generic: 'usd',
    calculated: 'calculator',
    sound: 'volume-up',
    velocity: 'speedometer'
  };

  private _asset: BehaviorSubject<Asset> = new BehaviorSubject(null);
  public asset: Observable<Asset> = this._asset.asObservable();

  private _setpoints: BehaviorSubject<Setpoint[]> = new BehaviorSubject(null);
  public setpoints: Observable<Setpoint[]> = this._setpoints.asObservable();

  private _offlineAssets: BehaviorSubject<any> = new BehaviorSubject(null);
  public offlineAssets: Observable<any> = this._offlineAssets.asObservable();

  private _offlineCloudConnectors: BehaviorSubject<any> = new BehaviorSubject(null);
  public offlineCloudConnectors: Observable<any> = this._offlineCloudConnectors.asObservable();

  public _assetTypes: BehaviorSubject<AssetType[]> = new BehaviorSubject([]);
  public assetTypes: Observable<AssetType[]> = this._assetTypes.asObservable();

  public assetIdFavList: number[];
  public assetFavList: Asset[];

  static factory(data?: any) {
    let asset = data;
    if (data && data.asset) {
      asset = data.asset;
    }
    const type = asset ? asset.assetType_id : null;

    switch (type) {
      case 42:
        switch (asset.purpose_id) {
          case 6:
            return new Occupancy(data);
          default:
            return new Asset(data);
        }
      case 43:
      case 44:
        return new Camera(data);
      default:
        return new Asset(data);
    }
  }

  constructor(private apiService: APIService, private http: HttpClient, private storeService: StoreService) { }

  async askQuestion(assetId: number, question: string) {
    const url = `${this.API_URL}question`;
    const params = new HttpParams()
      .set('id', assetId)
      .set('question', question);
    const options = { ...this.apiService.getUAOHeaders(), params };
    const response = await lastValueFrom(this.http.get<any>(url, options));

    return response;
  }

  async getTelemetry(assetId: number, start: Date, end: Date, cacheMaxAgeSeconds = 86400, limit = 11000, offset = 0) {
    const url = `${this.API_URL}telemetry`;

    const params = new HttpParams()
      .set('aid', assetId)
      .set('start', this.apiService.dbDate(start))
      .set('end', this.apiService.dbDate(end))
      .set('cacheMaxAge', cacheMaxAgeSeconds)
      .set('limit', limit)
      .set('offset', offset);

    const options = { ...this.apiService.getUAOHeaders(), params };
    return new Promise(async (resolve) => {
      return this.http.get<any>(url, options).subscribe(response => {

        resolve({ stats: response.stats, telemetry: response.telemetry.map(t => { return { i: t.i, v: t.v, d: new Date(t.c) } }) });
      });
    });
  }

  async getEnergyAssets(year?: number): Promise<IGetEnergyAsset[]> {
    return new Promise(async (resolve) => {
      this.getPath('energy', `year=${year}`).then(response => {
        resolve(response);
      });
    });
  }

  async getAssetOccupancy(assetId, forDate): Promise<IGetAssetOccupancy[]> {
    return new Promise(async (resolve) => {
      const dateDB = this.apiService.dbDate(forDate);
      this.getPath('occupancy', `id=${assetId}&date=${dateDB}`).then(response => {
        resolve(response.map(row => {
          return {
            datetime: new Date(row.datetime),
            minutes: row.minutes
          };
        }));
      });
    });
  }

  clearUserFavourites() {
    this.assetFavList = null;
    this.assetIdFavList = null;
  }

  async getUserFavourites(forceGet = false): Promise<Asset[]> {

    if (this.assetFavList && !forceGet) {
      return Promise.resolve(this.assetFavList);
    }

    return new Promise(async resolve => {
      const url = `${this.API_URL}favourites`;
      return this.http
        .get<Asset[]>(url, this.apiService.getUAOHeaders())
        .subscribe(assets => {
          this.assetFavList = assets.map(asset => new Asset(asset));
          this.assetIdFavList = assets.map(asset => asset.id);
          resolve(this.assetFavList);
        });
    });
  }

  async removeUserFavourite(assetId: number): Promise<any> {
    return new Promise(async resolve => {
      const url = `${this.API_URL}favourites`;

      this.assetFavList = this.assetFavList.filter(asset => asset.id !== assetId);
      this.assetIdFavList = this.assetIdFavList.filter(id => id !== assetId);
      return this.http
        .patch<any>(url, { assetId, action: 'remove' }, this.apiService.getUAOHeaders())
        .subscribe(response => resolve(response))
    });
  }
  async addUserFavourite(assetId: number, labels: string): Promise<any> {
    return new Promise(async resolve => {
      const url = `${this.API_URL}favourites`;

      return this.http
        .post<any>(url, { asset: { id: assetId, labels }, action: 'add' }, this.apiService.getUAOHeaders())
        .subscribe(response => resolve(response))
    });
  }

  async getOfflineCommunicationsForOrg(): Promise<OfflineIssue[]> {
    return new Promise(async resolve => {
      const url = `${this.API_URL}offline`;
      return this.http
        .get<any>(url, this.apiService.getUAOHeaders())
        .subscribe(response => resolve(response.issues.map(r => new OfflineIssue(r))))
    });
  }

  async getOfflineCommunications(assetId: number): Promise<OfflineIssue> {
    return new Promise(async resolve => {
      const url = `${this.API_URL}offline?id=${assetId}`;
      return this.http
        .get<any>(url, this.apiService.getUAOHeaders())
        .subscribe(response => resolve(new OfflineIssue(response)))
    });
  }

  async updateOfflineIssue(issue: OfflineIssue): Promise<any> {
    return new Promise(async resolve => {
      const url = `${this.API_URL}offline`;
      return this.http
        .post<any>(url, issue.serialise(), this.apiService.getUAOHeaders())
        .subscribe(response => resolve(response))
    });
  }

  async updateUserFavourites(assets: Asset[]): Promise<any> {
    return new Promise(async resolve => {
      const url = `${this.API_URL}favourites`;
      return this.http
        .post<any>(url, { assets: assets.map(asset => { return { id: asset.id, labels: asset.labels }; }) }, this.apiService.getUAOHeaders())
        .subscribe(response => resolve(response))
    });
  }

  async updateFavouriteAssetLabels(assetId: number, labels: string): Promise<any> {
    return new Promise(async resolve => {
      const url = `${this.API_URL}favourites`;
      return this.http
        .patch<any>(url, { assetId, labels }, this.apiService.getUAOHeaders())
        .subscribe(response => resolve(response))
    });
  }

  async getBatterysForBuilding(buildingId: number, dateFrom: Date, dateTo: Date): Promise<BatteryAsset[]> {
    return new Promise(async resolve => {
      const dateFromDB = this.apiService.dbDate(dateFrom);
      const dateToDB = this.apiService.dbDate(dateTo);
      const url = `${this.API_URL}batteries`;
      const params = { bid: buildingId, a: 'get', df: `${dateFromDB} 00:00:00`, dt: `${dateToDB} 00:00:00` };
      const headers = this.apiService.getUAOHeaders().headers;
      return this.http
        .get<any>(url, { params, headers })
        .subscribe(response => {

          const assets = response.assets.map(a => new BatteryAsset(a));
          const colorOrder = { "no-data": 0, red: 1, amber: 2, green: 3 };

          assets.sort((a, b) => {
            const aValue = colorOrder[a.battery.rag];
            const bValue = colorOrder[b.battery.rag];

            if (aValue < bValue) {
              return -1;
            }
            if (aValue > bValue) {
              return 1;
            }
            return 0;
          });
          resolve(assets);
        });
    });
  }

  async toggleUserFavourite(assetId: number, labels?: string): Promise<boolean> {
    if (!this.assetIdFavList) {
      // Get user favourites if not already done

      await this.getUserFavourites();
    }

    if (this.assetIdFavList.includes(assetId)) {
      await this.removeUserFavourite(assetId);
    } else {
      await this.addUserFavourite(assetId, labels);
    }

    await this.updateUserFavourites(this.assetFavList);
    await this.getUserFavourites(true);

    return this.assetIdFavList.includes(assetId);
  };
  /*
    getAssetFavsLocalStorage(orgId: number) {
      if (!this.assetIdFavList) {
        const storedValue = localStorage.getItem(`org:${orgId}:assetFavs`);
   
        this.assetIdFavList = (storedValue ? JSON.parse(storedValue) : []);
      }
   
      return this.assetIdFavList;
    }
   
    setAssetFavsLocalStorage(orgId: number) {
      localStorage.setItem(`org:${orgId}:assetFavs`, JSON.stringify(this.assetIdFavList));
    }
   
    getIsFav(assetId: number, orgId: number) {
   
      return this.getAssetFavsLocalStorage(orgId).includes(assetId);
    }
   
    clickFav(assetId: number, orgId: number) {
      let assetIdFavList = this.getAssetFavsLocalStorage(orgId);
   
      let isFav = this.getIsFav(assetId, orgId);
   
      if (isFav) {
        assetIdFavList = assetIdFavList.filter(id => id !== assetId);
      } else {
        assetIdFavList.push(assetId);
      }
   
      isFav = !isFav;
      this.setAssetFavsLocalStorage(orgId);
   
      return isFav;
    }
  */
  setAssetTypes(assetTypes: AssetType[]) {
    this._assetTypes.next(assetTypes.sort((a, b) => a.title > b.title ? 1 : -1));
  }

  getAssetTypes(): AssetType[] {
    return this._assetTypes.value;
  }

  async requestQueuedTelemetry(assetId: number, dateFrom: Date, dateTo: Date): Promise<IRequestQueuedTelemetry> {
    return new Promise(async (resolve) => {
      const dateFromDB = this.apiService.dbDate(dateFrom);
      const dateToDB = this.apiService.dbDate(dateTo);
      this.getPath('query', `id=${assetId}&df=${dateFromDB}&dt=${dateToDB}`).then(response => {
        resolve(response);
      });
    });
  }

  async getQueuedTelemetryStatus(key: string): Promise<IGetQueuedTelemetryStatus> {
    return new Promise(async (resolve) => {
      this.getPath('query', `key=${key}`)
        .then(response => {
          resolve(response);
        });
    });
  }

  async downloadDataFromAthenaKey(athenaKey: string): Promise<Packet[]> {
    return new Promise(async (resolve, reject) => {

      let url = `https://4d-athena-queries.s3.eu-west-2.amazonaws.com/${athenaKey}`;
      console.log(url);

      return this.http
        .get(url, { responseType: 'text' })
        .toPromise()
        .then((data) => {
          let rows = data.split("\n");

          const response: Packet[] = rows.filter(item => item !== '"v","d"' && item.length > 5)
            .map(item => {
              const row: any[] = item.split(",");
              const dt = new Date(row[1].split('"')[1]);

              return { i: +dt, v: +(row[0].split('"')[1]), d: dt, rag: null, inScope: false, w: null };
            })
            .sort((a, b) => a.i > b.i ? -1 : 1);

          resolve(response);
        });
    });
  }

  getAsset(assetId: Number, options: { cache?: boolean } = { cache: true }): Promise<Asset> {

    if (options.cache) {
      for (let idx = 0; idx < this.storeService.getAssets().length; idx++) {
        if (this.storeService.getAssets()[idx].id === assetId) {
          if (this.storeService.getAssets()[idx] instanceof Asset) {
            // console.log('Asset class object from local cache');
          }
          console.log(`from stack`, this.storeService.getAssets()[idx]);
          return new Promise((resolve, reject) => resolve(this.storeService.getAssets()[idx]));
        }
      }
    }

    return this._getAsset(assetId);
  }

  _getAsset(assetId): Promise<Asset> {
    const options = { headers: this.apiService.getHttpHeaders() };

    return this.http.get<any[]>(this.apiService.apiUrl + 'assets/' + assetId, options)
      .toPromise()
      .then((response) => {
        const asset = this.createAssetObject(response[0]);
        const assets = this.storeService.getAssets();

        this._asset.next(asset);
        for (let idx = 0; idx < assets.length; idx++) {

          if (assets[idx] && assets[idx].id === asset.id) {
            assets[idx] = asset;
            console.log('asset ' + asset.id + ' updated', asset);

            return asset;
          }
        }
        console.log(`new on stack`, asset);
        assets.push(asset);

        return asset;
      })
      .catch(this.apiService.handleError);
  }

  getOffline(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.get(`a=get-offline`)
        .then(assets => {
          this._offlineAssets.next(assets.map(asset => new Asset(asset)));
          resolve(assets);
        });
    });
  }

  getAnnotations(assetId: number): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.get(`a=get-annotations&id=${assetId}`)
        .then(annotations => {

          resolve(annotations);
        });
    });
  }


  // Get cloud collectors as well as assets
  getOfflineAll(): Promise<IGetOfflineAllResponse> {
    return new Promise((resolve, reject) => {
      this.get(`a=get-offline-all`)
        .then(response => {
          this._offlineAssets.next(response.assets.map(asset => new Asset(asset)));
          this._offlineCloudConnectors.next(response.connectors);
          resolve({ assets: this._offlineAssets.value, connectors: this._offlineCloudConnectors.value });
        });
    });
  }

  updateAsset(assetId: number, fields: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const payload = { id: assetId, ...fields };
      this.post(payload, `a=update-asset`)
        .then(response => {
          return response;
        });
    });
  }

  async getAssets(assetIds: number[]): Promise<Asset[]> {
    return new Promise((resolve, reject) => {
      this.get(`a=get-values&id=` + assetIds.join(','))
        .then(assets => resolve(assets.map(asset => new Asset(asset))));
    });
  }

  async getValues(assetIds: number[]): Promise<{ id: number, value: string | number }[]> {
    return new Promise((resolve, reject) => {
      this.get(`a=get-values&id=` + assetIds.join(','))
        .then(values => resolve(values));
    });
  }

  getAssetFromId(assetId: number) {
    this.asset.subscribe(asset => this._asset.next(asset));
    this._getAsset(assetId);
  }

  getTypeTitle(typeId: number): string {
    if (!this.assetTypes) {
      return null;
    }
    return this.getAssetTypes().find(type => +type.id === +typeId)?.title || String(typeId);
  }

  async getControlsForAssets(assetIds: number[]): Promise<IAssetControls> {
    const url = `${this.API_URL}send`;

    const params = new HttpParams()
      .set('a', 'get-controls')
      .set('id', assetIds.join(','));

    const options = { ...this.apiService.getUAOHeaders(), params };
    return new Promise(async (resolve) => {
      return this.http.get<any>(url, options).subscribe(response => {

        resolve(response);
      });
    });
  }

  async sendActionToAsset(assetId: number, actionUUID: string): Promise<IAssetControls> {
    const url = `${this.API_URL}send`;

    const params = new HttpParams()
      .set('a', 'run-uuid')
      .set('uuid', actionUUID)
      .set('id', assetId);

    const options = { ...this.apiService.getUAOHeaders(), params };
    return new Promise(async (resolve) => {
      return this.http.get<any>(url, options).subscribe(response => {

        resolve(response);
      });
    });
  }

  getIcon(asset: Asset) {
    if (!asset || asset.assetType_id === null) {
      return '';
    }
    const category = this.getAssetTypes().find(type => +type.id === +asset.assetType_id)?.category || '';

    return 'fa fa-fw fa-' + this.categoryIcons[category];
  }

  isNumeric(asset: Asset): boolean {
    if (!asset || asset.assetType_id === null) {
      return false;
    }
    const { dataType } = this.getAssetTypes().filter(type => +type.id === +asset.assetType_id)[0];

    if (dataType === 'integer' || dataType === 'decimal') {
      return true;
    }

    return false;
  }

  /**
   * Get image for asset;
   * @param Asset
   */
  getImage(asset: Asset, index?: number, limit?: number): any {
    return this.apiService.getAssetImage(asset.gateway_id, asset.id, index, limit);
  }

  getTelemetryForRange(id: number, fromDate: Date, toDate: Date) {
    return this.apiService.getTelemetryForRange(id, fromDate, toDate).then(
      values => values);
  }

  getTelemetryForRangeAsObjects(asset: Asset, fromDate: Date | string, toDate: Date | string): Promise<IGetTelemetryForRangeAsObjectsResponse[]> {
    return new Promise((resolve) => {
      this.apiService.getTelemetryForRange(asset.id, fromDate, toDate)
        .then(values => {
          if (!values?.length) {
            resolve(null);
          }
          resolve(values.map(row => {
            return { v: +row.v, c: row.c, m: moment(row.c) };
          }).sort((a, b) => a.m > b.m ? -1 : 1));
        });
    });
  }

  setSetpoints(setpoints: Setpoint[]) {
    this._setpoints.next(setpoints);
  }

  getPath(path: string, qs: string = ''): Promise<any> {
    return new Promise(resolve => {
      const url = `${this.API_URL}${path}?${qs}`;
      return this.http
        .get(url, this.apiService.getUAOHeaders())
        .subscribe(b => resolve(b));
    });
  }

  get(qs: string = ''): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = `${this.API_URL}?${qs}`;
      return this.http
        .get<any>(url, this.apiService.getUAOHeaders())
        .subscribe(b => {
          resolve(b);
        });
    });
  }

  post(body: any, qs: string = ''): Promise<any> {
    return new Promise((resolve) => {
      const url = `${this.API_URL}?${qs}`;
      return this.http
        .post<any>(url, body, this.apiService.getUAOHeaders())
        .subscribe(b => {
          resolve(b);
        });
    });
  }

  getAssetsForSite(siteId: number): Promise<Asset[]> {
    return new Promise(resolve => {
      const url = `${this.API_URL}site?siteid=${siteId}`;
      return this.http
        .get<any>(url, this.apiService.getUAOHeaders())
        .subscribe(b => {
          resolve(b);
        });
    });
  }

  /**
   * Get all of the assets the user has access to
   * @param type The asset type
   */
  getAssetsForUser(type?: number, sortBy?: string, dataset?: string): Promise<Asset[]> {
    console.log('getAssetsForUser()');
    const options = { headers: this.apiService.getHttpHeaders() };
    let query = 'sort=%7B';
    if (!sortBy) {
      query += '%22gateway_id%22:1%2C%22satellite_id%22:1';
    } else {
      query += sortBy;
    }
    query += '%7D';

    if (type) {
      query += `&type=${type}`;
    }

    if (dataset) {
      query += `&dataset=${dataset}`;
    }
    return this.http.get<any[]>(`${this.apiService.apiUrl}orgs/${this.apiService.getUserOrg().id}/assets?${query}`, options)
      .toPromise()
      .then((response) => {
        const body = response;
        const assets = body.map(asset => this.createAssetObject(asset));
        this.storeService.setAssets(assets);

        return assets;
      })
      .catch(this.apiService.handleError);
  }


  createAssetObject(asset: any) {
    const obj = new Asset(asset);

    const userOrg = this.apiService.getUserOrg();
    const orgId = obj.gateway.org.id || obj.gateway.site.org.id;

    if (orgId) {
      if (this.storeService.getOrgs().find(o => o.id === orgId && o.hide)) {
        obj.gateway.site.org = obj.gateway.org = userOrg;
      }

    }
    return obj;
  }
}
export interface IRequestQueuedTelemetry {
  data: {
    assetId: number,
    df: string,
    dt: string,
    email: string,
    key: string,
    orgId: number,
    queryId: number,
    sql: string,
    userId: number
  };
}
export interface IGetQueuedTelemetryStatus {
  status: string;
  athenaKey: string;
  notes: string;
  size: number;
  createdAt: Date;
}
export interface IGetTelemetryForRangeAsObjectsResponse {
  v: number;
  c: string;
  m: moment.Moment;
}

export interface IGetAssetOccupancy {
  datetime: Date;
  minutes: number;
}

export interface IGetEnergyAsset {
  id: number;
  title: string;
  assetType_id: number;
  months: number[];
  site_id: number;
  site_title: string;
  org_id: number;
}


export interface IGetOfflineAllResponse {
  connectors: IOfflineCloudConnector[];
  assets: any[];
}

export interface IOfflineCloudConnector {
  connection: string;
  createdAt: Date;
  gateway_id: string;
  id: number;
  lastSeenAt: Date;
  name: string;
  org_id: number;
  site_id: number;
  their_id: string;
}

export interface IAssetControls {
  assets: {
    asset_id: number;
    title: string;
    requesting: string;
    controls: {
      action: AssetControlActionType;
      uuid: string;
      title: string;
    }[]
  }[];
}

export type AssetControlActionType = 'actionOn' | 'actionOff';
