@defer {
  <article>
    <section class="no-print" [class.fullscreen]="fullscreen">
      @if (_showHeader() || true) {
        @defer {
          <app-setpoint-query-header [hasData]="!!packets()?.length" [view]="view" (onDateSelect)="headerDateSelect($event)" (onExport)="export()" [custom]="setpointQueryDateRange" [asset]="asset"> </app-setpoint-query-header>
        } @placeholder (minimum 100ms) {
          <div [style.height.px]="30"></div>
        }
      }
      @if (showHeaderDetails() && view !== 'compact') {
        <main>
          @if (hasActiveSetpoints()) {
            <div class="setpoints">
              <div class="heading">
                <div>Day</div>
                <div>Start</div>
                <div>End</div>
                <div class="text-right">Red Min</div>
                <div class="text-right">Amber Min</div>
                <div class="text-right">Amber Max</div>
                <div class="text-right">Red Max</div>
              </div>
              @for (setpoint of setpoints; track setpoint; let i = $index) {
                <div>
                  @if (setpoint.isActive) {
                    <div>{{ ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'][i] }}</div>
                    @if (!setpoint.allday) {
                      <div>{{ setpoint.startsAt }}</div>
                      <div>{{ setpoint.endsAt }}</div>
                    } @else {
                      <div>All Day</div>
                      <div></div>
                    }
                    <div class="text-right">{{ setpoint.red_min }}</div>
                    <div class="text-right">{{ setpoint.amber_min }}</div>
                    <div class="text-right">{{ setpoint.amber_max }}</div>
                    <div class="text-right">{{ setpoint.red_max }}</div>
                  }
                </div>
              }
            </div>
          }
        </main>
      }
    </section>

    @if (asset) {
      <div class="tabview-list">
        <div [class.active]="tab() === 'chart'" (click)="tab.set('chart')"><i class="mdi mdi-chart-line"></i> Chart</div>
        <div [class.active]="tab() === 'table'" (click)="tab.set('table')"><i class="mdi mdi-table"></i> Table</div>
        @if (showHeaderDetails() && view === 'compact' && hasActiveSetpoints()) {
          <!-- If hiding in compact, allow visibilty through a tab -->
          <div [class.active]="tab() === 'setpoint'" (click)="tab.set('setpoint')"><i class="mdi mdi-table"></i> Setpoints</div>
        }
        @if (isAdmin) {
          <div [class.active]="tab() === 'details'" (click)="tab.set('details')"><i class="mdi mdi-shield-account-outline"></i> <span pTooltip="Admin extended details">Details</span></div>
        }
        @if (asset.assetType_id == 50) {
          <div [class.active]="tab() === 'occupancy'" (click)="tab.set('occupancy')"><i class="mdi mdi-table"></i> Occupancy</div>
        }
        @if (asset.assetType_id == 16 && isAdmin) {
          <div [class.active]="tab() === 'power'" (click)="tab.set('power')"><i class="mdi mdi-table"></i> Power</div>
        }
        @if (asset.assetType_id == 4 || asset.assetType_id == 79 || asset.assetType_id == 80) {
          <div [class.active]="tab() === 'control'" (click)="tab.set('control')"><i class="mdi mdi-electric-switch"></i> Control</div>
        }
        @if (asset.assetType_id === 23) {
          <div [class.active]="tab() === 'questions'" (click)="tab.set('questions')"><i class="mdi mdi-head-question-outline"></i> Questions</div>
        }
        <div></div>
      </div>
      <div class="tabview-content">
        @switch (tab()) {
          @case ('control') {
            <app-setpoint-query-control [assetId]="asset?.id" />
          }
          @case ('details') {
            <app-asset-viewer [assetId]="asset?.id" [wantsChart]="false" [wantsExtendedDetails]="true" />
          }
          @case ('setpoint') {
            <main>
              @if (hasActiveSetpoints()) {
                <div class="setpoints">
                  <div class="heading">
                    <div>Day</div>
                    <div>Start</div>
                    <div>End</div>
                    <div class="text-right">Red Min</div>
                    <div class="text-right">Amber Min</div>
                    <div class="text-right">Amber Max</div>
                    <div class="text-right">Red Max</div>
                  </div>
                  @for (setpoint of setpoints; track setpoint; let i = $index) {
                    <div>
                      @if (setpoint.isActive) {
                        <div>{{ ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'][i] }}</div>
                        @if (!setpoint.allday) {
                          <div>{{ setpoint.startsAt }}</div>
                          <div>{{ setpoint.endsAt }}</div>
                        } @else {
                          <div>All Day</div>
                          <div></div>
                        }
                        <div class="text-right">{{ setpoint.red_min }}</div>
                        <div class="text-right">{{ setpoint.amber_min }}</div>
                        <div class="text-right">{{ setpoint.amber_max }}</div>
                        <div class="text-right">{{ setpoint.red_max }}</div>
                      }
                    </div>
                  }
                </div>
              }
            </main>
          }
          @case ('table') {
            <app-setpoint-query-table [packets]="packets()" [hasActiveSetpoints]="hasActiveSetpoints()" [asset]="_asset"> </app-setpoint-query-table>
          }
          @case ('occupancy') {
            <app-setpoint-query-occ [packets]="packets()" [asset]="asset" [setpointQueryDateRange]="setpointQueryDateRange" [chartPresetDate]="chartPresetDate"></app-setpoint-query-occ>
          }
          @case ('power') {
            <app-sq-power [packets]="packets()" [asset]="asset"></app-sq-power>
          }
          @case ('questions') {
            <app-setpoint-query-questions [asset]="asset" (showOnGraph)="showOnGraph($event)" />
          }
          @default {
            <div class="chart-container">
              @if (isWorking()) {
                <i class="fa fa-spin fa-refresh"></i>
              }
              @if (packets()?.length && annotations && setpoints) {
                <app-d3-chart [canAnnotate]="canAnnotate" [asset]="asset" [telemetry]="packets()" [showLegend]="showLegend" [setpoints]="setpoints" [annotations]="annotations" (dimensionsChanged)="chartDimensionChanged($event)" [height]="chartHeight" [width]="chartWidth" [xLegendTicks]="xLegendTicks" [showXLegend]="showXLegend" [pins]="pins" [legendTitles]="legendTitles" [weather]="weather"></app-d3-chart>
              } @else {
                @if (!hasData()) {
                  <app-info-panel>No data available</app-info-panel>
                } @else {
                  <div [style.height.px]="280"></div>
                }
              }
            </div>
          }
        }
      </div>
    }

    @if (assetContainers()) {
      <div class="charts-container" [class.has-value]="showValue">
        @for (assetContainer of assetContainers(); track assetContainer) {
          @if (assetContainer.isReady) {
            <div class="chart-value-container" [class.chart-only]="!_showValue()">
              <div>
                @if (_showValue()) {
                  <div class="value">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 33" class="{{ assetContainer.asset.rag }}">
                      <style>
                        text {
                          font-family: Lato, sans-serif;
                          font-weight: normal;
                          animation: text-in 2s;
                        }

                        text.v {
                          fill: rgb(40, 40, 40);
                        }

                        svg.amber text {
                          fill: rgb(235, 153, 3);
                        }

                        svg.green text {
                          fill: rgb(79, 160, 3);
                        }

                        svg.red text {
                          fill: rgb(255, 3, 3);
                        }

                        text.t {
                          fill: rgb(150, 150, 150);
                        }

                        text.u {
                          fill: rgb(150, 150, 150);
                        }

                        @keyframes text-in {
                          0% {
                            opacity: 0;
                          }

                          50% {
                            opacity: 0;
                          }

                          100% {
                            opacity: 1;
                          }
                        }
                      </style>
                      <svg:text y="22" x="17" text-anchor="middle" [style.font-size]="textFontSize" class="number">
                        {{ assetContainer.asset.value }}
                      </svg:text>
                      <svg:text y="3" x="0" text-anchor="left" font-size="3" class="t">
                        {{ assetContainer.asset.title }}
                      </svg:text>
                      <svg:text y="32" x="0" text-anchor="left" font-size="3" class="u">
                        {{ assetContainer.asset.updatedAt | amDateFormat: 'DD MMM YYYY HH:mm' }}
                      </svg:text>
                    </svg>
                  </div>
                }
              </div>
              @if (assetContainer.isReady) {
                <div>
                  @if (assetContainer.packets?.length) {
                    <app-d3-chart [asset]="assetContainer.asset" [dateFrom]="chartPresetDate.from" [dateTo]="chartPresetDate.to" [telemetry]="assetContainer.packets" [setpoints]="assetContainer.setpoints" [annotations]="assetContainer.annotations" (dimensionsChanged)="chartDimensionChanged($event)" [height]="chartHeight" [limitLines]="limitLines" [weather]="assetContainer.weather" [legendTitles]="legendTitles" [clickForValue]="clickForValue" (onValueChange)="onChartValueChange($event)">
                    </app-d3-chart>
                  } @else {
                    <p class="no-data">
                      <span>{{ assetContainer.asset.title }} HAS NO DATA</span>
                    </p>
                  }
                </div>
              } @else {
                <div style="height: 280px"></div>
              }
            </div>
          }
        }
      </div>
    }
  </article>
} @placeholder (minimum 100ms) {
  <div [style.height.px]="275"><i class="mdi mdi-loading mdi-spin-x2"></i></div>
}

<ng-template [ngIf]="isWaitingForData || isWaitingToConfirm">
  <div class="__modal0"></div>
  <div class="__modal lg">
    <header>
      <div>
        <div *ngIf="!athenaStatus" class="progress-line"></div>
        <div>
          <span *ngIf="!athenaStatus">Your data is being retrieved, please wait</span>
          <span *ngIf="athenaStatus?.status === 'succeeded'"><i class="mdi mdi-database-check green"></i> Your data is ready.</span>
          <span *ngIf="athenaStatus?.status === 'failed'"><i class="mdi mdi-database-alert red"></i> Your data retrieval failed.</span>
        </div>
      </div>
    </header>
    <div>
      <app-theme-page-section>
        <div>
          <div *ngIf="!athenaStatus">
            <p class="text-center">Please wait around 1 minute.</p>
            <p class="text-center">If you cancel, you can come back later and instantly retrieve the data.</p>
          </div>
          <div *ngIf="athenaStatus?.status === 'failed'" class="text-center">
            <p>Data retrieval failed</p>
            <pre>{{ athenaStatus?.notes }}</pre>
            <p>A message has been sent to the 4D support channel and someone will get back to you when the issue is resolved.</p>
          </div>
          <div *ngIf="athenaStatus?.status === 'succeeded'" class="info">
            <div></div>
            <div class="">
              <p><i class="mdi mdi-file-table-outline"></i> Formatted as csv</p>
              <p [class.red]="athenaStatus.size > 1000000">
                <i class="mdi" [class.mdi-size-s]="athenaStatus.size < 20000" [class.mdi-size-m]="athenaStatus.size >= 20000 && athenaStatus.size <= 100000" [class.mdi-size-l]="athenaStatus.size >= 100000"></i>
                {{ athenaStatus.size }} bytes
              </p>
              <p>
                <i class="mdi mdi-calendar"></i>
                Generated on {{ athenaStatus.createdAt | amDateFormat: 'DD MMM YYYY HH:mm:ss' }}
              </p>

              <p>
                <i class="mdi mdi-cloud-download"></i>
                <a class="link" target="_blank" href="https://4d-athena-queries.s3.eu-west-2.amazonaws.com/{{ athenaStatus.athenaKey }}"> Download</a>
              </p>
            </div>
            <div></div>
          </div>
        </div>
      </app-theme-page-section>
    </div>
    <footer>
      <p-button label="Cancel" icon="pi pi-times" styleClass="p-button-sm p-button-outlined p-button-secondary ml-1" (click)="isWaitingForData = false; isWaitingToConfirm = false; athenaStatus = null"> </p-button>
      <p-button *ngIf="athenaStatus?.status === 'succeeded'" label="Generate graph" icon="mdi mdi-chart-line" styleClass="p-button-sm p-button-primary ml-1" (click)="wantsToDownloadGeneratedAthenaData()"> </p-button>
    </footer>
  </div>
</ng-template>

@if (isGeneratingBlockingGraph()) {
  <div class="__modal0"></div>
  <div class="__modal lg">
    <header>
      <div>
        <div class="progress-line"></div>
        <div>
          <span>Your data is being downloaded & plotted, please be patient</span>
        </div>
      </div>
    </header>
    <div>
      <app-theme-page-section>
        <p class="text-center"><i class="mdi mdi-coffee"></i> Depending on your PC's specification, this may take some time.</p>
      </app-theme-page-section>
    </div>
    <footer></footer>
  </div>
}
@if (isLoading()) {
  <app-please-wait></app-please-wait>
}
