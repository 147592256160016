<div class="title-container" [class.size-medium]="size === 'medium'">
  <div [class.can-click]="canGoBack">
    @if (canGoBack) {
      <span class="nav-back"><i class="mdi mdi-chevron-left"></i> </span>
    }
    {{ label }}
    <ng-content></ng-content>
  </div>
  @if (links) {
    <div class="links">
      @for (link of links; track link) {
        @switch (link.type) {
          @case ('button') {
            <app-button size="medium" [label]="link.label" [icon]="link.icon ? 'mdi mdi-' + link.icon : ''" (click)="onLinkClick.emit(link)" />
          }
          @case ('button-sm') {
            <app-button size="small" [label]="link.label" [icon]="link.icon ? 'mdi mdi-' + link.icon : ''" (click)="onLinkClick.emit(link)" />
          }
          @default {
            <span (click)="onLinkClick.emit(link)"><i *ngIf="link.icon" class="mdi mdi-{{ link.icon }}"></i> {{ link.label }}</span>
          }
        }
      }
    </div>
  }
</div>
