@defer (when asset()) {
  <div class="tabview-list">
    <div [class.active]="tabIndex() === 0" (click)="tabChange(0)">Realtime</div>
    <div [class.active]="tabIndex() === 1" (click)="tabChange(1)">Query</div>
    <div></div>
  </div>
  <div class="tabview-content">
    @switch (tabIndex()) {
      @case (0) {
        @if (timeoutTimer <= 0) {
          <app-info-panel>Session has timed out, refresh the page to continue.</app-info-panel>
        }
        <div>
          <div [hidden]="notitle" class="history-header text-center" title="Refresh every {{ everySeconds }} seconds">
            <h4>Realtime feed</h4>
          </div>
        </div>
        <div [class.tick1]="tick" [class.tick2]="!tick">
          <div>
            <div style="width: 500px; margin: 0 auto">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th class="text-right">Value</th>
                  </tr>
                </thead>
                <tbody>
                  @for (packet of packets; track packet.uuid) {
                    <tr>
                      <td class="hidden-xs" tooltipPosition="left" pTooltip="{{ packet.createdAt | date: 'dd MMM YY HH:mm' }} - {{ packet.value }}">
                        {{ packet.createdAt | date: 'dd-MM-YY HH:mm' }},
                        <small> {{ packet.createdAt | amTimeAgo }} </small>
                      </td>
                      <td class="hidden-sm visible-xs hidden-md hidden-lg" title="{{ packet.createdAt | date: 'dd MMM YY HH:mm' }}">
                        {{ packet.createdAt | amTimeAgo }}
                      </td>
                      <td class="text-right">{{ formattedValue(packet.value) }}</td>
                    </tr>
                  }
                </tbody>
              </table>
              <p class="text-center text-small">
                @if (timeoutTimer > 0) {
                  refreshes every {{ everySeconds }} seconds , this session has {{ timeoutTimer / 2 | number: '1.0-0' }} minutes remaining.
                }
              </p>
            </div>
          </div>
        </div>
      }
      @case (1) {
        @if (isMobile) {
          <app-setpoint-query [asset]="asset()" [fullscreen]="fullscreen"></app-setpoint-query>
        } @else {
          <app-explorer-asset [asset]="asset()"></app-explorer-asset>
        }
      }
    }
  </div>
} @placeholder (minimum 200ms) {
  <span><i class="mdi mdi-loading mdi-spin-x2"></i> Please wait...</span>
}
