@defer (when controls()) {
  <article>
    <main class="mt-1 mb-1">
      <div class="flex">
        @if (controls().assets?.length > 0) {
          @for (control of controls().assets[0].controls; track control.uuid) {
            <div class="ml-1 mr-1">
              <app-button [label]="control.title" (click)="clickControl(control)" />
            </div>
          }
        }
      </div>
    </main>
  </article>
} @placeholder {
  <i class="mdi-loading mdi mdi-spin-x2"></i>
}
@if (!canControl) {
  <app-info-panel>You do not have permission to operate this asset</app-info-panel>
}
