import { Injectable } from '@angular/core';
import moment from 'moment';
@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor() { }

  static getWeekday(d: Date) {
    const weekdays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
    const weekday = d.toLocaleDateString('en-gb', { weekday: 'long' }).toLowerCase();

    return { weekday, index: weekdays.indexOf(weekday) };
  }

  static month(d: Date): string {
    return d.toLocaleString('default', { month: 'long' });
  }

  static date(d: Date): string {
    return ('00' + d.getDate()).slice(-2) + '/' +
      ('00' + (d.getMonth() + 1)).slice(-2) + '/' +
      d.getFullYear();
  }

  static datetime(d: Date): string {
    return this.date(d) + ' ' +
      ('00' + d.getHours()).slice(-2) + ':' +
      ('00' + d.getMinutes()).slice(-2) + ':' +
      ('00' + d.getSeconds()).slice(-2);
  }


  static time(d: Date): string {
    return ('00' + d.getHours()).slice(-2) + ':' +
      ('00' + d.getMinutes()).slice(-2) + ':' +
      ('00' + d.getSeconds()).slice(-2);
  };

  static monthsDiff(dateFrom: Date, dateTo: Date): number {
    return (dateTo.getFullYear() * 12 + dateTo.getMonth()) - (dateFrom.getFullYear() * 12 + dateFrom.getMonth());
  }

  static dbDate(date: any) {
    if (date === null || typeof date === 'undefined') {
      return null;
    }
    if (typeof date === 'string') {
      date = new Date(date);
    }

    return date.getFullYear() + '-' +
      ('00' + (date.getMonth() + 1)).slice(-2) + '-' +
      ('00' + date.getDate()).slice(-2);
  }

  static dbDateTime(date: any) {
    const dateString = DateService.dbDate(date);
    const timeString = DateService.time(date);

    return dateString + ' ' + timeString;
  }

  /**
   * Return dates from start date backwards in quarters (3 months)
   * 
   * @param startDate The starting date to count backwards
   */
  static quarters(startDate?: Date, rows = 8): IQuarter[] {
    const quarters = [, 'January to March', 'April to July', 'July to September', 'October to December'];
    const dates: IQuarter[] = [];
    for (let index = 1; index <= rows; index++) {
      const dt = moment(startDate).subtract(index * 3, 'month').startOf('month');
      const quarter = dt.quarter();
      const year = dt.year();
      const monthIndex = (quarter - 1) * 3;
      const date = new Date(year, monthIndex, 1, 0, 0, 0);
      dates.push({ title: quarters[quarter] + ' ' + dt.get('year'), quarter, year, date, monthIndex });
    }

    return dates;
  }

  static minutesToEnglish(minutes) {
    const days = Math.floor(minutes / (60 * 24));
    const remainingMinutes = minutes % (60 * 24);
    const hours = Math.floor(remainingMinutes / 60);

    let result = '';
    if (days > 0) {
      result += `${days} day${days > 1 ? 's' : ''}`;
      if (hours > 0) result += ' ';
    }
    if (hours > 0) {
      result += `${hours} hour${hours > 1 ? 's' : ''}`;
    }
    if (result === '') {
      result = '0 hours';
    }
    return result;
  }

  static durationToWords(durationString) {
    // Split the duration string into hours and minutes
    if (!durationString || durationString === '') {
      return '';
    }
    let [hours, minutes] = durationString.split(':').map(Number);

    const hourWords = [
      '', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten',
      'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'
    ];
    const tensWords = ['', '', 'twenty', 'thirty', 'forty', 'fifty'];

    let hoursWord = '';
    let minutesWord = '';

    if (hours > 0) {
      if (hours < 20) {
        hoursWord = hourWords[hours];
      } else {
        hoursWord = tensWords[Math.floor(hours / 10)] + (hours % 10 !== 0 ? ' ' + hourWords[hours % 10] : '');
      }
      hoursWord += hours === 1 ? ' hour' : ' hours';
    }

    if (minutes > 0) {
      if (minutes < 20) {
        minutesWord = hourWords[minutes];
      } else {
        minutesWord = tensWords[Math.floor(minutes / 10)] + (minutes % 10 !== 0 ? '-' + hourWords[minutes % 10] : '');
      }
      minutesWord += minutes === 1 ? ' minute' : ' minutes';
    }

    // Construct the final sentence
    if (!hoursWord && !minutesWord) return 'zero minutes';
    return `${hoursWord} ${hoursWord && minutesWord ? 'and' : ''} ${minutesWord}`.trim();
  };

  static addMonths(date: Date, months: number) {
    return moment(date).add(months, 'months').toDate();
  }

  static addMonthsToEndOfMonth(date: Date, months: number) {
    return moment(date).add(months, 'months').endOf('month').toDate();
  }

  static toStartOfDay(date: Date) {
    const newDate = new Date(date); // Clone the original date to avoid mutating it
    newDate.setHours(0, 0, 0, 0);  // Set hours, minutes, seconds, milliseconds to 0

    return newDate;
  }

  static toEndOfDay(date: Date) {
    const newDate = new Date(date); // Clone the original
    newDate.setHours(23, 59, 59, 999); // 11:59:59.999 PM
    return newDate;
  };

  static formattedDate(date: Date) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = String(date.getFullYear()).slice(-2);

    return `${day}/${month}/${year}`;
  }
}

export interface IQuarter {
  title: string;
  quarter: number;
  year: number;
  date: Date;
  monthIndex: number;
}
