import { SelectedAsset } from './selected-asset';
/**
 * @version 1
 */
export class RuleCondition {
	id: number;
	rulePackage_id: number;
	leftAsset: SelectedAsset;
	action: RuleConditionPickerActionsType;
	#operator: RuleConditionOperator;
	// Set to create a unique reference when creating
	uuid: string;

	public set operator(v: RuleConditionOperator) {
		this.#operator = v;
		switch (v) {
			case '<':
				this.operatorSafe = 'LT';
				break;
			case '<=':
				this.operatorSafe = 'LE';
				break;
			case '=':
				this.operatorSafe = 'EQ';
				break;
			case '>':
				this.operatorSafe = 'GT';
				break;
			case '>=':
				this.operatorSafe = 'GE';
				break;
			case '<>':
				this.operatorSafe = 'NE';
				break;
			case 'diff':
				this.operatorSafe = 'DIFF';
				break;
			case '<diff':
				this.operatorSafe = 'LTDIFF';
				break;
			case 'diff>':
				this.operatorSafe = 'GTDIFF';
				break;
		}
	}

	public get operator(): RuleConditionOperator {
		return this.#operator;
	}

	operatorSafe: RuleConditionOperatorSafe;
	rightAsset: SelectedAsset;
	inAlert: boolean; // via backend
	updatedAt: string;
	logic: string; // and/or

	constructor(rulePackageId?: number, id?: number, left?: SelectedAsset, operator?: RuleConditionOperator, right?: SelectedAsset, logic?: string, action?: RuleConditionPickerActionsType) {
		this.rulePackage_id = rulePackageId || null;
		this.id = id ?? 0;
		this.leftAsset = left || new SelectedAsset(id);
		this.rightAsset = right || new SelectedAsset(id);
		this.operator = operator || null;
		this.action = action || 'asset-to-value';
		this.inAlert = null; // Initialise as unknown alert status
		this.logic = logic || null;
		if (isNaN(id)) {
			this.uuid = crypto.randomUUID();
		}
	}

	serialise(): any {
		return {
			id: this.id,
			rulePackage_id: this.rulePackage_id,
			operator: this.operator,
			logic: this.logic,
			action: this.action,
			uuid: this.uuid,
			leftAsset: {
				asset: {
					id: (this.leftAsset.asset?.id ? this.leftAsset.asset.id : null),
					value: this.leftAsset.value ?? '',
					identifier: (this.leftAsset.asset ? this.leftAsset.asset.identifier : null)
				},
				value: this.leftAsset.value ?? null
			},
			rightAsset: {
				asset: {
					id: (this.rightAsset.asset?.id ? this.rightAsset.asset.id : null),
					value: this.rightAsset.value ?? '',
					identifier: (this.rightAsset.asset ? this.rightAsset.asset.identifier : null)
				},
				value: this.rightAsset.value ?? null
			}
		}
	}



	serialiseDeprecated(): any {

		return {
			id: this.id,
			rulePackage_id: this.rulePackage_id,
			operator: this.operator,
			leftAsset: {
				id: (this.leftAsset.asset ? this.leftAsset.asset.id : null),
				value: this.leftAsset.value || '',
				identifier: (this.leftAsset.asset ? this.leftAsset.asset.identifier : null)
			},
			rightAsset: {
				id: (this.rightAsset.asset ? this.rightAsset.asset.id : null),
				value: this.rightAsset.value || '',
				identifier: (this.rightAsset.asset ? this.rightAsset.asset.identifier : null)
			}
		}
	}
}

export type RuleConditionType = 'asset' | 'weather' | 'value' | 'collection';
export type RuleConditionOperator = '<' | '<=' | '=' | '>' | '>=' | '<>' | 'diff' | '<diff' | 'diff>';
export type RuleConditionOperatorSafe = 'LT' | 'LE' | 'EQ' | 'GT' | 'GE' | 'NE' | 'DIFF' | 'LTDIFF' | 'GTDIFF';
export type RuleConditionSide = 'left' | 'right';
export type RuleConditionWeatherType = 'temperature' | 'pressure' | 'humidity' | 'windspeedmph' | 'rainmm';
export type RuleConditionCollectionType = 'equipment';

export type RuleConditionPickerActionsType = 'asset-to-asset' | 'asset-to-value' | 'asset-to-missing';
