import { Component, input } from '@angular/core';

@Component({
  selector: 'app-data-noteworthy-tags',
  standalone: false,
  templateUrl: './data-noteworthy-tags.component.html',
  styleUrl: './data-noteworthy-tags.component.css'
})
export class DataNoteworthyTagsComponent {

  tags = input.required<IDataNoteworthyTag[]>();

}

export interface IDataNoteworthyTag {
  label: string;
  icon: string;
}
