import { Component, OnInit, Output, EventEmitter, Input, signal } from '@angular/core';
import { Asset } from 'app/classes/asset';
import { DateType, SetpointQueryDateRange } from '../setpoint-query.component';

import moment from 'moment';
import { MenuItem } from 'primeng/api';
import { APIService } from 'app/shared/api.service';
import { IMinMaxDates } from 'app/setpoints/setpoint-dates-button/setpoint-dates-button.component';
import { AssetService } from 'app/shared/asset.service';

@Component({
  selector: 'app-setpoint-query-header',
  templateUrl: './setpoint-query-header.component.html',
  styleUrls: ['./setpoint-query-header.component.css'],
  standalone: false
})
export class SetpointQueryHeaderComponent implements OnInit {

  @Output() onDateSelect: EventEmitter<IOnDateSelect> = new EventEmitter();

  @Output() onExport: EventEmitter<void> = new EventEmitter();

  previousAsset: Asset;

  minmax = signal<IMinMaxDates>(null);

  _asset: Asset;
  @Input()

  public set asset(v: Asset) {
    if (this.previousAsset?.id === v?.id) {
      // Ignore if the same asset
      return;
    }

    if (v.redis?.lastat) {
      // We have extended date info
      const dt = moment(v.redis.lastat).format('DD MMM YYYY');
      if (moment().diff(dt, 'days') > 7) {
        this.lastXTitleFromUpdate = `Last updated ${dt} `;
        this.lastXLabel = '7 days upto ' + dt;
      }
    } else {

      const dt = moment(v.updatedAt).format('DD MMM YYYY');
      if (moment().diff(dt, 'days') > 7) {
        this.lastXTitleFromUpdate = `Last updated ${dt} `;
        this.lastXLabel = '7 days upto ' + dt;
      }
    }
    this._asset = v;

    this.minmax.set({ min: v.getLastFirstTelemeteyDates().from, max: v.getLastFirstTelemeteyDates().to });

    this.checkAsset();
  }

  public get asset(): Asset {
    return this._asset;
  }

  @Input()
  hasData: boolean;

  _custom = signal<SetpointQueryDateRange>(null);

  @Input()
  public set custom(v: SetpointQueryDateRange) {
    if (v && !v?.type) {
      // If only a from/to supplied then set to custom
      this._custom.set(new SetpointQueryDateRange({ type: 'custom', ...v }));
    } else {
      this._custom.set(v);
    }
    this.checkAsset();
  }

  public get custom(): SetpointQueryDateRange {
    return this._custom();
  }

  enabled: { [key: string]: boolean } = {
    today: true,
    yesterday: true,
    thisweek: true,
    lastweek: true,
    lastx: true,
    custom: true
  };

  readonly lastX = 7;

  @Input()
  view: 'compact' | 'full' | 'chart-only';

  LOCALSTORAGE_KEY = 'setpoint:query:daterange';

  items: MenuItem[];

  isAdmin: boolean;

  lastXLabel = '7 days';
  lastXTitleFromUpdate = '';

  constructor(private apiService: APIService) {
    this.isAdmin = apiService.isAdmin();
  }

  ngOnInit(): void {
    if (!this._custom()) {
      const setpointDateRangeString = localStorage.getItem(this.LOCALSTORAGE_KEY);

      this._custom.set(new SetpointQueryDateRange(setpointDateRangeString, this.asset));
      if (this.custom.type === 'custom') {
        // Don't allow custom to be the default
        this.custom.type = 'today';
      }
    }
    this.onDateSelect.emit({ setpointQueryDateRange: this.custom, event: 'constructor' });
  }

  datesDialogClosed(ev?: SetpointQueryDateRange) {
    if (ev) {
      this._custom.set(ev);
      this.dateTypeSelect('custom');
    }
  }

  checkAsset() {
    if (this._asset) {
      // Only allow today if we have data today
      this.enabled.today = (+moment(this.asset.updatedAt) > +moment().startOf('day'));
      this.enabled.yesterday = (+moment(this.asset.updatedAt) > +moment().subtract(1, 'day').startOf('day'));
      this.enabled.thisweek = (+moment(this.asset.updatedAt) > +moment().isoWeekday(1).startOf('isoWeek'));
      this.enabled.lastweek = (+moment(this.asset.updatedAt) > +moment().subtract(1, 'week').isoWeekday(1).startOf('isoWeek'));

      if (this._custom) {
        if (this._custom()?.type === 'today' && !this.enabled.today) {
          this._custom().type = 'yesterday';
        }
        if (this._custom()?.type === 'yesterday' && !this.enabled.yesterday) {
          this._custom().type = 'thisweek';
        }
        if (this._custom()?.type === 'thisweek' && !this.enabled.thisweek) {
          this._custom().type = 'lastx';
        }
        if (this._custom()?.type === 'lastx' && !this.enabled.lastx) {
          this._custom().type = 'custom';
          this._custom().setFromTo(this.asset.redis?.lastat || this.asset.updatedAt, this.asset.redis?.lastat || this.asset.updatedAt);
        }

        this.items =
          [{
            label: 'Export',
            items: [
              { label: 'Excel', icon: 'mdi mdi-microsoft-excel', command: () => this.export() }
            ]
          }];

        if (this._asset.assetType_id === AssetService.ASSET_TYPE_MOISTURE) {
          this.items.push({
            label: 'Questions',
            items: [
              { label: 'The last leak?', icon: 'mdi mdi-database-search-outline', command: () => this.question('last-leak') }
            ]
          });
        }
      }
    }
  }

  dateTypeSelect(dateType: DateType) {

    if (this.enabled[dateType]) {
      this.custom.type = dateType;
      this.saveLocalStorage();
      this.onDateSelect.emit({ setpointQueryDateRange: this._custom(), event: 'click' });
    }
  }

  saveLocalStorage() {
    localStorage.setItem(this.LOCALSTORAGE_KEY, JSON.stringify(this._custom().serialse()));
  }

  export() {
    this.onExport.emit();
  }

  question(question: 'last-leak') {

  }

  onClickMenu(ev) {
    console.log(ev);
  }
}

export interface IOnDateSelect {
  setpointQueryDateRange: SetpointQueryDateRange;
  event: 'constructor' | 'click';
}
