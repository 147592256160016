<app-toolbar [class.is-in-dialog]="isInDialog()">
  <div class="crumbs left">
    <section>
      <span (click)="crumbClick(99)" pTooltip="List sites" tooltipPosition="bottom"><i class="fa fa-home fa-fw"></i></span>
      @for (crumb of breadcrumb; track crumb; let i = $index) {
        <span (click)="crumbClick(i)">
          {{ crumb.label }}
        </span>
      }
    </section>
  </div>
  <div class="right">
    @if (_selectMode()) {
      <app-button size="medium" (click)="selectClear()"> <i class="mdi mdi-check"></i>&nbsp; Clear </app-button>
      <app-button size="medium" (click)="selectSave()"> <i class="mdi mdi-check"></i>&nbsp; Use Selected </app-button>
    } @else {
      <app-button size="small" (click)="crumbFavouriteAssetsClick()"> <i class="mdi mdi-star"></i> Favourites </app-button>
      @if (view() === 'sites' && !_selectMode()) {
        <app-button size="small" label="Export" icon="mdi mdi-file-download" (click)="export('sites')" />
      }
    }
  </div>
</app-toolbar>

<main #contextContainer>
  @switch (view()) {
    @case ('sites') {
      <section>
        <div class="search">
          <app-search-input [searchText]="filtered.text.sites" [resultCount]="filtered.counts.sites" (onSearchChanged)="searchChanged('sites', $event)" tooltipText="Search sites"></app-search-input>
        </div>
        @if (filtered.counts.sites) {
          <div class="content-container">
            <table class="table table-sm table-hover sites" aria-label="Site list">
              <thead>
                <tr>
                  @if (api || _isAdmin()) {
                    <th class="isDesktop">id</th>
                  }
                  <th>Site</th>
                  <th>Address</th>
                  <th class="isDesktop">Postcode</th>
                  <th class="isDesktop text-right">Weather</th>
                  <th class="isDesktop text-right"># Gateways</th>
                </tr>
              </thead>
              <tbody>
                <ng-template ngFor [ngForOf]="filtered.lists.sites" let-site>
                  <tr (click)="siteClick(site)" [class.has-rags]="site.rags.length">
                    @if (api || _isAdmin()) {
                      <td class="isDesktop">{{ site.id }}</td>
                    }
                    <td>
                      @if (_selectMode()) {
                        <app-chip [checked]="site.checked" (click)="select(site, $event)"></app-chip>
                      }
                      <span>{{ site.title }}</span>
                    </td>
                    <td tooltipPosition="bottom" [pTooltip]="site.address.displayForLine()">
                      {{ site.address.addressTown }}
                    </td>
                    <td class="isDesktop">
                      {{ site.address.addressPostcode }}
                    </td>
                    <td class="isDesktop text-right">
                      @if (site.metobsid) {
                        <app-weather-temp [site]="site" />
                      }
                    </td>
                    <td class="isDesktop text-right">
                      {{ site.gatewayCount }}
                    </td>
                  </tr>
                  @if (site.rags?.length) {
                    <tr class="rags isDesktop">
                      <td colspan="5">
                        <div style="display: flex">
                          @for (rag of site.rags; track site.id) {
                            <div tooltipPosition="bottom" (click)="ragClick(rag, site)" (mouseenter)="ragHover(rag, site)" [pTooltip]="rag.day + '-' + rag.month + '-' + rag.year" style="flex: 1" class="rag-{{ rag.rag }}" [class.is-even]="rag.month % 2" [class.is-first]="rag.day === 1">&nbsp;</div>
                          }
                        </div>
                        @if (ragDetails?.siteId === site.id) {
                          <div class="detail-container">
                            <div>
                              <span style="padding: 0 6px 0 6px; font-size: 20px; margin-right: 10px"> {{ ragDetails.rag.day }}-{{ ragDetails.rag.month }}-{{ ragDetails.rag.year }} </span>
                              @if (!_selectMode()) {
                                <button pTooltip="Export this month and previous 3 months as an Excel spreadsheet" class="btn btn-secondary btn-sm" (click)="export()"><span class="mdi mdi-file-download"></span> Export</button>
                              }
                            </div>

                            <ng-template ngFor [ngForOf]="ragDetails.assets" let-row let-i="index">
                              <div *ngIf="i === 0" class="header">
                                <div>Asset</div>
                                <div class="text-right">Average</div>
                                <div class="text-right">Min</div>
                                <div class="text-right">Max</div>
                              </div>
                              <div>
                                <div>
                                  <span class="rag-{{ row.day.rag }}">&nbsp;&nbsp;</span>
                                  {{ row.asset?.title }}
                                </div>
                                <div class="text-right">{{ row.day.avg | number: '0.2-2' }}</div>
                                <div class="text-right">{{ row.day.min | number: '0.2-2' }}</div>
                                <div class="text-right">{{ row.day.max | number: '0.2-2' }}</div>
                              </div>
                            </ng-template>
                          </div>
                        }
                      </td>
                    </tr>
                  }
                </ng-template>
              </tbody>
            </table>
          </div>
        }
      </section>
    }
    @case ('gateways') {
      <div class="content-container">
        <section>
          <app-explorer-site [site]="site" [gatewaysChecked]="gatewaysChecked" (onSelected)="gatewayClick($event)" (onGatewayChecked)="gatewaySelected($event)" (isLoading)="isLoading = $event" [selectMode]="_selectMode()"> </app-explorer-site>
        </section>
      </div>
    }
    @case ('favourites') {
      @if (isLoadingFavourites()) {
        <i class="mdi mdi-loading mdi-spin-x2"></i>
      }
      <section class="content-container">
        @if (assets?.length === 0) {
          <app-info-panel> You have no favourites, click the <i class="mdi mdi-star-outline"></i> star next to sensors where you see them. </app-info-panel>
        }
        @for (labels of assetLabels; track labels.key) {
          <!-- If default, dont show header-->
          @if (labels.key !== 'default') {
            <h1 pTooltip="Assets favourite collection">{{ labels.key }}</h1>
          }

          <table class="table table-sm table-hover assets" aria-label="Favourite sensors">
            <thead>
              <tr>
                <th>Site</th>
                <th>Gateway</th>
                <th>Asset</th>
                <th class="isDesktop">Type</th>
                <th stlye="width:140px">Updated</th>
                <th style="width: 100px">Value</th>
              </tr>
            </thead>
            <tbody>
              @for (asset of labels.assets; track asset.id) {
                <tr (click)="assetClick(asset)" [class.hours-24]="asset.daysOld >= 1" (contextmenu)="mouseClick(asset)">
                  <td>{{ asset.gateway.site.title }}</td>
                  <td>{{ asset.gateway.title }}</td>
                  <td pTooltip="{{ asset.id }}" tooltipPosition="left">{{ asset.title }}</td>
                  <td>{{ asset.assetTypeTitle }}</td>
                  <td class="isDesktop updated-at">
                    <span>{{ asset.updatedAt | amTimeAgo }}</span>
                  </td>
                  <td style="width: 140px; overflow: hidden; line-break: anywhere" class="text-right" pTooltip="{{ asset.value }}" tooltipPosition="left">
                    {{ asset.displayValue() }}
                  </td>
                </tr>
              }
            </tbody>
          </table>

          <p-contextMenu [target]="contextContainer" [model]="items" (onShow)="showContextMenu()"></p-contextMenu>

          @if (labelEnterVisible) {
            <div class="label-modal">
              <section>
                <input maxlength="150" id="label" name="label name" class="form-control" type="text" [(ngModel)]="rightClickedAssset.labels" />
              </section>

              <section>
                <p-button label="Cancel" icon="pi pi-cross" styleClass="p-button-secondary p-button-outlined p-button-sm ml-1" (click)="labelEnterVisible = false"></p-button>
                <p-button label="Save" icon="pi pi-check" styleClass="p-button-sm ml-1" (click)="updateLabel()"></p-button>
              </section>
            </div>
          }
        }
      </section>
    }
    @case ('assets') {
      <section class="content-container">
        <table class="table table-sm table-hover assets" aria-label="Assets">
          <thead>
            <tr>
              @if (api || _isAdmin()) {
                <th>id</th>
              }
              <th>Asset</th>
              <th class="isDesktop">Type</th>
              <th>Your Ref</th>
              <th stlye="width:140px">Updated</th>
              <th style="width: 100px; text-align: right">Value</th>
            </tr>
          </thead>
          <tbody>
            @for (asset of assets; track asset.id) {
              <tr (click)="assetClick(asset)" [class.hours-24]="asset.daysOld >= 1">
                @if (api || _isAdmin()) {
                  <td>{{ asset.id }}</td>
                }
                <td>
                  @if (_selectMode()) {
                    <app-chip [checked]="asset.checked" (click)="select(asset, $event)"></app-chip>
                  }
                  {{ asset.display || asset.title }}
                </td>
                <td>{{ asset.assetTypeTitle }}</td>
                <td>{{ asset.ref }}</td>
                <td class="isDesktop updated-at">
                  <span>{{ asset.updatedAt | amTimeAgo }}</span>
                </td>
                <td style="width: 140px; overflow: hidden; line-break: anywhere" class="text-right" pTooltip="{{ asset.value }}" tooltipPosition="left">
                  {{ asset.displayValue() }}
                </td>
              </tr>
            }
          </tbody>
        </table>
        @if (api) {
          <p-card>
            <p><strong>Get gateway assets values</strong></p>
            <p>
              <a target="_blank" href="https://2rx4xrq7i1.execute-api.eu-west-2.amazonaws.com/v1/assets?gateway={{ gateway.id }}&key={{ api.apikey }}"> https://2rx4xrq7i1.execute-api.eu-west-2.amazonaws.com/v1/assets/?gateway={{ gateway.id }}&key={{ api.apikey }} </a>
              <i tooltipPosition="bottom" pTooltip="Copy to clipboard" class="fa fa-fw fa-copy" (click)="copyToClipboard('gateway')"></i>
              <span *ngIf="copied" class="badge">Copied.</span>
              <input id="gatewayapi" value="https://2rx4xrq7i1.execute-api.eu-west-2.amazonaws.com/v1/assets?gateway={{ gateway.id }}&key={{ api.apikey }}" />
            </p>
          </p-card>
        }
      </section>
    }

    @case ('asset') {
      <section class="asset">
        <app-explorer-asset [api]="api" [asset]="asset" [site]="site"></app-explorer-asset>
        <div>
          @if (!showAPIpush) {
            <span style="color: #e9e9e9; font-weight: 100; text-decoration-color: #e0e0e0 !important" class="link" (click)="showAPIpush = true">show api push panel</span>
          } @else {
            @if (selectType === 'asset') {
              <div class="api-push mt-1">
                @if (asset && asset.id && moduleAccess.api) {
                  <app-explorer-api [asset]="asset"></app-explorer-api>
                }
              </div>
            }
          }
        </div>
        @if (api) {
          <div>
            <p-card>
              <p><strong>Get current asset value</strong></p>
              <p>
                <app-dev-curl [api]="api" [path]="'assets?id=' + asset.id"></app-dev-curl>
                <!--
                    <a target="_blank"
                        href="https://2rx4xrq7i1.execute-api.eu-west-2.amazonaws.com/v1/assets?id={{asset.id}}&key={{api.apikey}}">
                        https://2rx4xrq7i1.execute-api.eu-west-2.amazonaws.com/v1/assets?id={{asset.id}}&key={{api.apikey}}
                    </a>-->
                <!--<i tooltipPosition="bottom" pTooltip="Copy to clipboard" class="fa fa-fw fa-copy"
                        (click)="copyToClipboard()"></i>
                    <span *ngIf="copied" class="badge">Copied.</span>-->
              </p>

              <div class="output"></div>

              <!--
				<input id="assetapi"
					value="https://2rx4xrq7i1.execute-api.eu-west-2.amazonaws.com/v1/assets?id={{asset.id}}&key={{api.apikey}}">-->
            </p-card>
          </div>
        }
      </section>
    }
  }
</main>

@if (pleaseWait()) {
  <app-please-wait></app-please-wait>
}
