@defer (when asset()) {
  <app-button label="When was the Last leak?" (click)="question('last-leak')" />

  <div class="response mt-1">
    {{ response() }}
    @if (date()) {
      &nbsp; <a class="can-click" (click)="clickShowOnGraph()"><i class="mdi mdi-open-in-new"></i> show on graph</a>
    }
  </div>
} @placeholder (minimum 100ms) {
  <i class="mdi mdi-loading mdi-spin-x2"></i>
}
